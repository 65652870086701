.container {
  display: flex;
  justify-content: space-between;
  padding-top: 50px;
  padding-bottom: 50px;
  /* background-color: #977967; */
  width: 100%;
}

.leftSection {
  flex: 1;
  padding: 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-top: 80PX;

}

.description {
  margin-bottom: 20px;

  
}

.typeSelector {
  width: 30%;
  margin: 0 auto;
  display: flex;
  gap: 15px;

  
}

.typeCircle {
  cursor: pointer;
  padding: 5px;
  border: 2px solid transparent;
  transition: border 0.3s ease;

}



.active {
  border: 2px solid #ff9800; /* لون عند التحديد */
  border-radius: 50%;
}

.rightSection {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coverImage {
  max-width: 100%; /* ضمان ملاءمة الصورة */
}

@media(max-width:678px){
  .container {
    flex-direction: column;
  }
  .typeSelector {
    margin: 0 ;
    text-align: center;
    width:100%;
    justify-content: center;
  }
  .leftSection {

    margin-top: 0;
  
  }
}