:root {
  --luna-p-color: rgb(70, 70, 70);
  --luna-h2-color: rgb(153, 0, 255);
  --luna-p-white-color: rgb(255, 255, 255);
  --text-p-color: #343a40;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.container p {
  color: var(--luna-p-color);
  line-height: 1.6;
  font-size: 16px;
  width: 85%;
  margin: 10px auto 50px auto;
  z-index: 1;
}

.container img
 {
  width: 100%;
  height: auto;
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  overflow: hidden;
}

.container h2 {
  font-weight: bold;
  font-size: 16px;
  color: var(--luna-h2-color);
}

.container .imgContainer p {
  font-size: 20px;
  color: var(--luna-p-white-color);
  width: 100%;
}

/* .videoContainer {
  max-width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  z-index: 1;
} */

.videoContainer {
  position: block;
  width: 100%;
  overflow: hidden;
  z-index: -1; /* خلف المحتوى الأساسي */
}

.fullscreenVideo {
  width: 100%;
  height: 100%;
  object-fit: cover; /* لجعل الفيديو يغطي الشاشة دون تشويه */
  pointer-events: none; /* منع التفاعل مع الفيديو */

}




/* Responsive Styles */
@media (max-width: 768px) {
  .container h2 {
    font-size: 14px;
  }

  .container .imgContainer p {
    font-size: 13px;
  }
}

@media (max-width: 1200px) {
  .imgContainer {
    width: 100%;
    margin: 0 auto;
  }
}

/* Product Details Section */
.productDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  overflow: hidden;
  margin-top: 60px;
}

.productImage {
  flex: 1;
}

.productImage img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: block;
}

.productInfo {
  width: 50%;
  padding: 1.5rem 4rem;
  flex: 1;
  margin: auto 0;
}

.productTitle {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.productFeatures {
  list-style: none;
  padding: 0;
  line-height: 1.8;
  font-size: 1.2rem;
  color: #666;
}

.productFeatures li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}

.productFeatures li::before {
  content: '✔';
  position: absolute;
  left: 0;
  color: #007bff;
}

[dir='rtl'] .productFeatures li::before {
  right: -25px;
  left: unset;
}

.productFeatures li:last-child::before {
  content: none;
}

/* Overlay Text */
.textOverlay {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
}

@media (max-width: 768px) {
  .textOverlay {
    top: 30%;
    width: 90%;
    font-size: 1rem;
  }

  .imgContainer img {
    width: 100%;
    height: auto;
  }
}

/* Additional Media Queries */
@media (max-width: 576px) {
  .productTitle {
    font-size: 1.5rem;
  }

  .productInfo {
    padding: 1rem 2rem;
  }

  .productFeatures {
    font-size: 1rem;
  }
}

@media (max-width: 375px) {
  .container .imgContainer p {
    font-size: 12px;
  }
}
