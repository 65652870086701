/* Footer.css */

.footer {
  background-color: #282c34;
  color: #fff;
  text-align: center;
  padding: 20px 0;
  width: 100%;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: auto;
}

.footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-text {
  margin-bottom: 10px;
  font-size: 14px;
  color: #fff;
}

.icon-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.footer-icon {
  margin: 0 10px;
  color: #fff;
  font-size: 24px;
  transition: color 0.3s ease;
}


.footer-icon:hover {
  /* color: #a302b1;  */
  /* color: var(--secondary-color); */
  color: rgb(255, 82, 232);
}
.footer .by {
  

}




