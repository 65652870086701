.container {
  position: fixed;
  bottom: 92%;
  left: 0px;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 10000000000;
}

.icon {
  background-color: #88008d;
  color: #ffffff;
  border-radius: 10px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  opacity: 70%;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  border: none;
}

.icon:hover {
  transform: scale(1.1);
  background-color: #25d366; /* لون الخلفية عند التحويم */
  color: #fff; /* لون النص عند التحويم */
  opacity: 100%;


}

.icon:first-child {
  background-color: #88008d;
}

/* .icon:first-child:hover {
  background-color: #007bff;
} */
