@import "https://unpkg.com/leaflet@1.9.4/dist/leaflet.css";

:root {
  --primary-color: #8c00ff;
  --secondary-color: #a900d3;
  --accent-color: #b341ff;
  --background-color: #f4f4f4;
  --text-color: #555;
  --overlay-color: rgba(0, 0, 0, 0.5);
}
*:focus {
  outline: none;
  box-shadow: none;
}
a:focus,
a:active,
button:focus,
button:active {
  outline: none;
  box-shadow: none;
}

html[dir="rtl"] {
  direction: rtl;
}

html[dir="ltr"] {
  direction: ltr;
}

* {
  box-sizing: border-box;
  outline: none;
}
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
}
html {
  overflow-x: hidden;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff;
}
.content {
  flex: 1;
  padding: 20px;
  text-align: center;
}
.footer {
  background-color: var(--primary-color);
  color: white;
  padding: 10px;
  text-align: center;
  margin-top: auto; 
  width: 100%;
  position: relative;
}

.product-container {
  text-align: center;

}
.products {
  display: flex;
  gap: 40px;
  justify-content: center;
  background-color: rgb(245, 245, 245);
}

.air-products, .bus-products, .bee-products, .locks-products, .hik-products, .Access-products, .sound-products{
  display: flex;
  justify-content: center;
  gap: 15px;
  background-color: rgb(71, 71, 71);
  color: white;
  text-align: center;
  padding: 15px;
  cursor: pointer;
  margin: 0 auto;
  border-radius: 5px;
}


.tisLocksImg,
.tisBusImg ,
.tisBeeImg ,
.tisAirImg {
display: flex;
}

.tisLocksImg img,
.tisBusImg img,
.tisBeeImg img,
.tisAirImg img{
  display: flex;
/* width: 100%; */
width: 120px;
height: 100px;
padding: 10px 0px;

}
.air-products span, .bus-products span, .bee-products span, .locks-products span, .hik-products span, .Access-products span, .sound-products span  {
  display: block;
  padding: 5px 10px;
  border-radius: 7px;
  font-size: 18px;
  margin: 0 auto;
}

.air-product, .bus-product, .bee-product, .locks-product, .hik-product, .Access-product, .sound-product{
  transition: .5s;
}

.air-product:hover, .bus-product:hover, .bee-product:hover, .locks-product:hover, .hik-product:hover,
.Access-product:hover, .sound-product:hover {
  color: #da21ff;
}

.access-wifi-img,
.access-unifi-img,
.access-tplink-img,
.access-dlink-img,
.sound-bosch-img,
.sound-bose-img,
.hik-intercom-img,
.hik-out-img,
.locks-smart-img,
.locks-hotel-img,
.panels-img,
.hotel-interface-img,
.screens-img,
.dimmers-img,
.relays-img,
.sensors-img,
.other-img,
.air-gateways-img,
.air-intercom-img,
.air-sensors-img,
.air-controls-img,
.air-panel-img,
.bee-wall-switch,
.bee-controller,
.bee-security-sensor,
.bee-lights,
.bee-gateway {

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 35px;
text-align: center;
  margin: 50px;
}
.access-wifi-img img,
.access-unifi-img img,
.access-tplink-img img,
.access-dlink-img img,
.sound-bosch-img img,
.sound-bose-img img,
.hik-intercom-img img,
.hik-out-img img,
.locks-smart-img img,
.locks-hotel-img img,
.panels-img img,
.hotel-interface-img img,
.screens-img img,
.dimmers-img img,
.relays-img img,
.sensors-img img,
.other-img img,
.air-gateways-img img,
.air-intercom-img img,
.air-sensors-img img,
.air-controls-img img,
.air-panel-img img,
.bee-wall-switch img,
.bee-controller img,
.bee-security-sensor img,
.bee-lights img,
.bee-gateway img {
  width: 200px;
  transition: .7s;
  z-index: -1;
  
}
.access-wifi-img h5,
.access-unifi-img h5,
.access-tplink-img h5,
.access-dlink-img h5,
.sound-bosch-img h5,
.sound-bose-img h5,
.hik-intercom-img h5,
.hik-out-img h5,
.locks-smart-img h5,
.locks-hotel-img h5,
.panels-img h5,
.hotel-interface-img h5,
.screens-img h5,
.dimmers-img h5,
.relays-img h5,
.sensors-img h5,
.other-img h5,
.air-gateways-img h5,
.air-intercom-img h5,
.air-sensors-img h5,
.air-controls-img h5,
.air-panel-img h5,
.bee-wall-switch h5,
.bee-controller h5,
.bee-security-sensor h5,
.bee-lights h5,
.bee-gateway  h5{
  display: flex;
  justify-content: center;
  position: absolute;
  text-align: center;
  padding: 10px 20px;
  border-radius: 5px;
  width: 200px;
  margin: 0 auto;
  z-index: 5000;
  color: black;
}

.access-wifi-img h5:hover,
.access-unifi-img h5:hover,
.access-tplink-img h5:hover,
.access-dlink-img h5:hover,
.sound-bosch-img h5:hover,
.sound-bose-img h5:hover,
.hik-intercom-img  h5:hover,
.hik-out-img  h5:hover,
.locks-smart-img h5:hover,
.locks-hotel-img h5:hover,
.panels-img h5:hover,
.hotel-interface-img h5:hover,
.screens-img h5:hover,
.dimmers-img h5:hover,
.relays-img h5:hover,
.sensors-img h5:hover,
.other-img h5:hover,
.air-gateways-img h5:hover,
.air-intercom-img h5:hover,
.air-sensors-img h5:hover,
.air-controls-img h5:hover,
.air-panel-img h5:hover,
.bee-wall-switch h5:hover,
.bee-controller h5:hover,
.bee-security-sensor h5:hover,
.bee-lights h5:hover,
.bee-gateway  h5:hover{
  color: #d400ff;
}
.access-wifi-img img:hover,
.access-unifi-img img:hover,
.access-tplink-img img:hover,
.access-dlink-img img:hover,
.sound-bosch-img img:hover,
.sound-bose-img img:hover,
.hik-intercom-img img:hover,
.hik-out-img img:hover,
.locks-smart-img img:hover,
.locks-hotel-img img:hover,
.panels-img img:hover,
.hotel-interface-img img:hover,
.screens-img img:hover,
.dimmers-img img:hover,
.relays-img img:hover,
.sensors-img img:hover,
.other-img img:hover,
.air-gateways-img img:hover,
.air-intercom-img img:hover,
.air-sensors-img img:hover,
.air-controls-img img:hover,
.air-panel-img img:hover,
.bee-wall-switch img:hover,
.bee-controller img:hover,
.bee-security-sensor img:hover,
.bee-lights img:hover,
.bee-gateway img:hover {
  scale: 1.2;
  z-index: -1;
}


.prod-text {
  display: flex;
  justify-content: center;
  font-size: 20px;
  align-items: center;
  
}

.header {
  position: relative;
  background-color: rgb(137, 0, 216);
  padding: 1rem;
  display: flex; 
  justify-content: space-between; 
  align-items: center;
}

.header ul {
  list-style: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0;
  margin: 0;
}

.header li {
  margin: 0;
}

.header a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
}

.hamburger {
  display: none;
}

.header-list {
  display: flex; 
  gap: 20px; 
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 800px;
  justify-content: center;
}

.header-list a {
  color: #fff ;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
  padding: 10px; 
  
}
@media (max-width: 1150px) {
  .header-list a {
    font-size: 15px;
  }
}
@media (max-width: 1030px) {
  .header-list a {
    font-size: 13px;
    padding: 0px; 
  }
}

.header-list a:hover {
  color: rgba(17, 180, 255, 0.897);
}

.logo {
  display: block;
  align-items: center;
  margin: 0 auto;
  width: 300px;
  text-align: center;
}

.logo img {
  width: 60px;
  height: 50px;
}

.logo-text {
  font-weight: bold;
  font-size: 11px;
  color: #fff;
  text-align: center;
  margin: 0 auto;
  display: block;
}

.header .warranty {
  width: 150px;
}
.header .warranty img {
  width: 55px;
  color: "#fff"
}
.header .warranty p {
  font-size: 10px;
  color: rgb(255, 255, 255);
  padding: 0;
  
}



/*================================Styles for small screens (mobile)====================== */
@media (max-width: 768px) {
  .header-list {
    flex-direction: column;
    gap: 20px;
  }
  .logo {
    display: block;
    align-items: center;
    margin-left: 0 ;
    width: 150px;
    text-align: center;

  }
  .logo img {
    width: 60px;
  }
  .logo-text {
    display: block;
    font-size: 10px;
    width: 150px;
    margin: 0 auto;
    /* text-align: center; */
    text-align: center;
  }
  .logo-text h4 {
    width: 150px;
  }
  .landing-page {
    height: 50vh;
  }
  .landing-page .overlay h1 {
    font-size: 2rem;
  }
  .landing-page .overlay p {
    font-size: 1.2rem;
  }
  .products {
    flex-direction: column;
    gap: 20px;
  }
  .air-products, .bus-products, .bee-products, .locks-products, .hik-products, .Access-products, .sound-products {
    font-size: 1.2rem;
  }

  .access-wifi-img,
.access-unifi-img,
.access-tplink-img,
.access-dlink-img,
.sound-bosch-img,
.sound-bose-img,
  .hik-intercom-img,
.hik-out-img,
  .locks-smart-img,
  .locks-hotel-img,
  .panels-img,
  .hotel-interface-img,
  .screens-img,
  .dimmers-img,
  .relays-img,
  .sensors-img,
  .other-img,
  .air-gateways-img,
  .air-intercom-img,
  .air-sensors-img,
  .air-controls-img,
  .air-panel-img,
  .bee-wall-switch,
  .bee-controller,
  .bee-security-sensor,
  .bee-lights,
  .bee-gateway {
    margin: 40px 20px 80px 20px;
  }
  .access-wifi-img img,
.access-unifi-img img,
.access-tplink-img img,
.access-dlink-img img,
.sound-bosch-img img,
.sound-bose-img img,
  .hik-intercom-img img,
.hik-out-img img,
  .locks-smart-img img,
  .locks-hotel-img img,
  .panels-img img,
  .hotel-interface-img img,
  .screens-img img,
  .dimmers-img img,
  .relays-img img,
  .sensors-img img,
  .other-img img,
  .air-gateways-img img,
  .air-intercom-img img,
  .air-sensors-img img,
  .air-controls-img img,
  .air-panel-img img,
  .bee-wall-switch img,
  .bee-controller img,
  .bee-security-sensor img,
  .bee-lights img,
  .bee-gateway img {
    width: 150px;
    /* margin-bottom: 50px; */
  }
  .hik-intercom-img img,
  .hik-out-img img{
    margin-top: 20px;
  }
  .access-wifi-img h5,
.access-unifi-img h5,
.access-tplink-img h5,
.access-dlink-img h5,
.sound-bosch-img h5,
.sound-bose-img h5,
  .hik-intercom-img h5,
.hik-out-img h5,
  .locks-smart-img h5,
.locks-hotel-img h5,
  .panels-img h5,
.hotel-interface-img h5,
.screens-img h5,
.dimmers-img h5,
.relays-img h5,
.sensors-img h5,
.other-img h5,
.air-gateways-img h5,
.air-intercom-img h5,
.air-sensors-img h5,
.air-controls-img h5,
.air-panel-img h5,
.bee-wall-switch h5,
.bee-controller h5,
.bee-security-sensor h5,
.bee-lights h5,
.bee-gateway  h5{
  width: 150px;
}
  .header-list {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: flex-start; /* Align items to the top */
    align-items: flex-start;
    list-style: none;
    margin: 0;
    padding: 1rem;
    position: fixed;
    top: 0;
    right: 0;
    width: 50%;
    height: 40%;
    background-color: rgba(0, 0, 0, 0.7); /* Transparent background */
    transform: translateX(100%);
    transition: transform 0.3s ease-in-out;
    z-index: 2;
  }
/* عند استخدام الاتجاه من اليمين إلى اليسار (RTL) */
[dir='rtl'] .header-list {
  right: auto;
  left: 0;
  transform: translateX(-100%);
  height: 45%;
}
  .header-list.active {
    transform: translateX(0);
    z-index: 10000;
  }
  .header-list ul {
    padding: 0;
    margin: 0;
    width: 100%;
  }
  .header-list li {
    width: 100%;
  }
  .header-list a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    font-size: 18px;
    display: block; /* Make the link block-level to take full width */
    padding: 10px; /* Add some padding */
    /* border-bottom: 1px solid #969696; */
    align-items: center;
    transition: 0.5s;
  }
  .header-list a:hover {
    color: rgba(17, 180, 255, 0.897);
    margin-left: 10px;
  }

  .hamburger {
    display: block; /* Show the hamburger icon on small screens */
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 4000000000;
  }
  [dir='rtl'] .hamburger {
    left: 1rem;
    right: auto;
  }
  .hamburger span {
    display: block;
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 5px 0;
    transition: 0.3s;
  }
  .hamburger.open span {
    color: #ffffff;
  }

  .hamburger.open span:nth-child(1) {
    transform: rotate(-45deg) translate(-5px, 6px);
  }

  .hamburger.open span:nth-child(2) {
    opacity: 0;
  }

  .hamburger.open span:nth-child(3) {
    transform: rotate(45deg) translate(-5px, -6px);
  }
  .tisLocksImg img,
  .tisBusImg img,
  .tisBeeImg img,
  .tisAirImg img{
  width: 85px;
  height: 85px;
  }


  .tisLocksImg,
  .tisBusImg ,
  .tisBeeImg ,
  .tisAirImg {
  display: flex;
  }
  .products {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 5px;
  }

  .locks-products, .air-products, .bus-products, .bee-products, .hik-products, .Access-products, .sound-products {
    display: block;
    padding: 0;
  }
  .locks-products span, .air-products span, .bus-products span, .bee-products span, .hik-products span, .Access-products span, .sound-products span{
    display: flex;
    padding: 15px;
    align-items: center;
    justify-content: center;
  }
  .border-bottom {
    border: 1px solid rgb(255, 255, 255);
    opacity: 0.3;
    }
    .header .warranty {
      display: block;
      width: 250px;
      align-items: center;
      /* margin: auto 0; */
      /* padding-top: 5px; */
    }
    .header .warranty img {
      display: block;
      width: 45px;
      color: "#fff";
      text-align: center;
      margin: 0 auto;
    }
    .header .warranty p{
      font-size: 8px;
      text-align: center;

      color: #f4f4f4;
      padding: 5px;
      margin: 0;
  
      
    }

}

@media (max-width: 1400px) {
  .bus-products{
    display: block;
  }
  .bus-products .border-bottom {
    border: 1px solid rgb(255, 255, 255);
    opacity: 0.3;
    }
    .bus-products span {
      padding: 15px;
    }
}

@media(max-width: 1120px){
  .tisLocksImg img,
  .tisBusImg img,
  .tisBeeImg img,
  .tisAirImg img{
  width: 75px;
  height: 75px;
  }
}