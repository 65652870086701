.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  background-color: black; /* خلفية داكنة كما في الصورة */
}

/* وضع الصور في منتصف القسم الأيسر */
.imageContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image {
  max-width: 80%;
  border-radius: 20px;
}

/* تنسيق النص في القسم الأيمن */
.textContainer {
  flex: 1;
  color: white;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* المحاذاة الافتراضية لليسار */
}

/* تنسيق النصوص في الخيارات */
.options p {
  cursor: pointer;
  margin-bottom: 1rem;
  font-size: 1.2rem;
}

.options .active {
  font-weight: bold;
  color: red;
}

.description {
  margin-top: 2rem;
  font-size: 1rem;
}

/* **التحكم في التصميم للأجهزة المحمولة** */
@media (max-width: 768px) {
  .container {
    flex-direction: column; /* التبديل إلى اتجاه عمودي */
    text-align: center; /* توسيط النصوص */
  }

  .imageContainer, .textContainer {
    width: 100%; /* جعل المحتوى يأخذ عرض الصفحة بالكامل */
    justify-content: center;
    align-items: center;
  }

  .textContainer {
    align-items: center; /* توسيط النصوص داخل الحاوية */
    padding: 1rem; /* تقليل التباعد قليلاً */
  }

  .options p {
    font-size: 1rem; /* تقليل حجم النص قليلاً على الأجهزة الصغيرة */
  }
}
