/* FAQ.module.css */
body {
  font-family: Arial, sans-serif;
}
.faqContainer {

  /* max-width: 900px; */
  width: 70%;
  margin: 30px auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.faqContainer h2 {
  text-align: center;
  color: #333;
  font-size: 1.8em;
  margin-bottom: 20px;
  font-weight: 600;
}

.faqItem {
  background-color: #fff;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 15px;
  transition: background-color 0.3s ease;
}

.faqItem:hover {
  background-color: #f0f0f0;
}

.faqQuestion {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  font-size: 1.2em;
  color: #333; /* تأكيد اللون هنا ليكون ثابتًا */
  font-weight: 600;
  padding-bottom: 10px;
}
.faqQuestion h3 {
  color: #333;
  font-size: 18px;
}
.faqQuestion:hover h3{
  color: #cc00ff;
}

.faqAnswer {
  font-size: 1em;
  color: #555;
  padding-top: 10px;
}

.faqAnswer p {
  margin: 0;
  line-height: 1.5;
  font-size: 16px;
}

.faqQuestion span {
  font-size: 1.5em;
  color: #cc00ff;
  transition: transform 0.3s ease;
}

.faqQuestion span:hover {
  transform: rotate(45deg);
}

/* Responsive */
@media (max-width: 768px) {
  .faqContainer {
    padding: 15px;
  }

  .faqContainer h2 {
    font-size: 1.6em;
  }

  .faqQuestion {
    font-size: 1.1em;
  }

  .faqAnswer {
    font-size: 0.95em;
  }
}
