.containerverna {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  gap: 20px; 
}

.thumbnails {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px; /* مسافة بين الأيقونات */
}
.thumbnail {
  width: 50px;  /* عرض الأيقونة */
  height: 50px; /* ارتفاع الأيقونة */
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s, border-color 0.2s;
  border: 2px solid transparent;
  object-fit: cover; /* لضبط الصورة داخل الأيقونة */
}
.thumbnail:hover {
  transform: scale(1.2); /* تكبير عند التمرير */
}

/* .thumbnail.active {
  border: 2px solid #000;
} */

.mainImage {
  width: 400px;
  transition: transform 0.3s ease; /* حركة خفيفة عند التبديل */
}

@media (max-width: 1200px) {
  .containerverna {
    flex-direction: column;
    margin: 0;
  }

  .mainImage {
    width: 300px; /* تصغير الصورة قليلاً على الشاشات الصغيرة */
  }

  .thumbnail {
    margin-bottom: 5px;
  }
  .thumbnails {
    display: block;
  }
}
