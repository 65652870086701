

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  gap: 20px; /* إضافة مسافة بين الأقسام */
}

.leftSection {
  max-width: 50%;
}

.leftSection p {
  font-size: 18px;
  line-height: 1.6;
  margin-bottom: 50px;
  text-align: center;
}

.buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.gangButton {
  padding: 10px 20px;
  border: 1px solid #ccc;
  background-color: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.gangButton.active {
  border-color: red;
  color: red;
  font-weight: bold;
}

.rightSection {
  max-width: 45%;
  text-align: center;
}

.gangImage {
  width: 100%;
  max-width: 800px;
  border-radius: 12px;
  transition: opacity 0.5s ease;
}

/* تصميم للموبايل */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
    text-align: center; /* لتوسيط النص */
  }

  .leftSection {
    max-width: 100%;
  }

  .buttons {
    justify-content: center;
  }

  .rightSection {
    max-width: 100%;
  }

  .gangImage {
    max-width: 80%; /* تكبير الصورة قليلاً في وضع الموبايل */
  }
}
