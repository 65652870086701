/* WhatsappPopup.module.css */
.whatsappPopup {
  position: fixed;
  bottom: 20px;
  left: 20px;
  background-color: #25D366;
  color: white;
  padding: 10px 15px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  z-index: 9999999999999999999999999;
}

.whatsappPopup:hover {
  background-color: #20b957;
}

.whatsappIcon {
  font-size: 1rem;
  margin-right: 10px;
}

.whatsappPopup span {
  font-size: 1rem;
  font-weight: 500;
}
