.productDetails {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #f9f9f9;
  overflow: hidden;
  padding-top: 40px;
}
.productImage {
  flex: 1;
  /* background-color: red; */
  padding-left: 50px;
}
[dir= "rtl"] .productImage {
  padding-right: 50px;
}
.productImage img {

  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: block;
  text-align: center;
  /* margin: 0  auto 50px auto; */
  padding: 0px 80px;

}

.productInfo {
  width: 50%;
  padding: 1.5rem 4rem;
  flex: 1;
  align-items: center;
  margin:  auto 0;
}

.productTitle {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  color: #333;
}

.productFeatures {
  list-style: none;
  padding: 0;
  line-height: 1.8;
  font-size: 1.2rem;
  color: #666;
}

.productFeatures li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
}


.productFeatures li::before{
  content: '✔'; /* يمكنك استخدام أيقونة هنا بدلاً من الرمز */
  position: absolute;
  left: 0;
  color: #007bff;
}

[dir= "rtl"] .productFeatures li::before{
  content: '✔'; /* يمكنك استخدام أيقونة هنا بدلاً من الرمز */
  position: absolute;
  right: -25px;
  color: #007bff;
}
.productFeatures li:last-child::before {
  content: none; /* إلغاء محتوى before للعنصر الأخير */
}
@media(max-width:668px){
  .productDetails {
    display: block;
  }
  .productInfo  {
    width: 100%;
    flex: none;
    padding: 1.5rem ;
  }
  .productImage {
    flex: none;
    padding: 10px 20px;
  }
  [dir= "rtl"] .productFeatures li::before{
    right: -3px;

  }

  [dir= "rtl"] .productImage {
    padding: 10px 20px;
  }
  
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.productTitle {
  font-size: 1.8rem;
  margin-bottom: 1.5rem;
  color: #333;
  animation: slideIn 1s ease-in-out; /* تطبيق الحركة */
}

.productFeatures li {
  position: relative;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
  opacity: 0;
  animation: slideIn 1s ease-in-out forwards;
  animation-delay: 0.2s;
}
[dir= "rtl"] .productFeatures li {
  padding-right: 1.5rem;
  padding-left: 0;
}
.productFeatures li:nth-child(2) {
  animation-delay: 0.4s;
}

.productFeatures li:nth-child(3) {
  animation-delay: 0.6s;
}

.productFeatures li:nth-child(4) {
  animation-delay: 0.8s;
}

.productFeatures li:nth-child(5) {
  animation-delay: 1s;
}
