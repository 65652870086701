
@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(90px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-90px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-90px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    transform: translateX(90px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes zoomIn {
  0% {
    transform: scale(0.8); /* يبدأ بحجم أصغر */
    opacity: 0;
  }
  100% {
    transform: scale(1); /* يعود إلى الحجم الأصلي */
    opacity: 1;
  }
}

.text {
  white-space: pre-line;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

}

.fadeInUp {
  opacity: 1;
  transform: translateY(0);
  animation: fadeInUp 0.8s ease-in-out;
}

.fadeInDown {
  opacity: 1;
  transform: translateY(0);
  animation: fadeInDown 0.8s ease-in-out;
}

.fadeInLeft {
  opacity: 1;
  transform: translateX(0);
  animation: fadeInLeft 0.8s ease-in-out;
}

.fadeInRight {
  opacity: 1;
  transform: translateX(0);
  animation: fadeInRight 0.8s ease-in-out;
}

.zoomIn {
  animation: zoomIn 0.8s ease-in-out;
}

