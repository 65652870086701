.scroller {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(153, 0, 255);
  /* background-color: var(--primary-color); */
  width: 100px;
  height: 5px;
  z-index: 9999;
  border-radius: 5px;
  z-index: 10000;
}
