

.toTop {
  width: 35px;
  height: 35px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #8c00ff;
  padding: 10px;
  border-radius: 50%;
  cursor: pointer;
  display: none;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.3s ease-in-out;
  transition: transform 0.3s;
  z-index: 9999999999999999999999999999999999999999999999;
  opacity: 0.4;
}

.toTop:hover {
  transform: scale(1.2);
  opacity: 1;
}

.show {
  display: flex;
  animation: bounce 1s infinite;
}

.arrowIcon {
  font-size: 24px;
  color: white;
}

/* تأثير التلاشي عند الظهور */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}