.container {
  padding: 50px 150px;
  /* background-color: #f1f1f1; */
  background-color: #f5f5f5;
}

.imageSection {
  text-align: center;
  margin-bottom: 70px;
}
.imageSlider {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.imageSlider img {
  width: 300px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.overview {
  margin: 20px 0;
}

.specifications {
  margin: 20px 0;
}

.specSection {
  margin-bottom: 10px;
  cursor: pointer;
}

.specSection h3 {
  background-color: #f0f0f0;
  color: #000;
  padding: 10px;
  border-radius: 5px;
}

.specDetails {
  margin: 10px 0;
  padding-left: 20px;
  list-style-type: none;
}

.specDetails li {
  margin-bottom: 5px;
}



@media (max-width: 768px) {
  .container {
    padding: 50px;
    width: 100vw;
  }
}
