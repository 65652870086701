.container {
  padding: 20px;
  text-align: center;
}

.title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.description {
  font-size: 1.2rem;
  text-align: center;
  margin-bottom: 2rem;
}

.videoGrid {
  display: flex;

  /* grid-template-columns: repeat(auto-fill, minmax(360px, 1fr)); */
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.videoCard {
  background-color: #f0f0f0;
  border: 1px solid #ddd;
  padding: 1rem;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.2s;
  min-width: 320px;
}
.videoCard h2 {
  margin-bottom: 30px;
}
.videoCard:hover {
  transform: scale(1.2);
}

/* .videoWrapper {
  position: relative;
  width: 100%;
  padding-top: 177.78%; 
  transition: 0.5s;
}
.videoWrapper:hover {
  scale: 1.06;
}
.video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border: none;

} */


.videoWrapper {
  position: relative;
  width: 100%;
  padding-top: 177.78%; /* نسبة العرض إلى الارتفاع لمقاطع الفيديو (9:16) */
  transition: 0.5s;
}
/* .videoWrapper:hover {
  scale: 1.06;
} */
.video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  border: none;
  pointer-events: auto;
}
@media (max-width: 768px){
  .video {
    pointer-events: none; 
  }
}

/* VideoGallery.module.css */
.videoWrapper {
  position: relative;
  width: 100%;
  max-width: 320px;
  margin: auto;
}







