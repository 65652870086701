/* ImageSwitcher.module.css */
.imageSwitcher {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.image {
  height: auto;
  border-radius: 10px;
  cursor: pointer;
  text-align: right;
  padding: 0px 50px;

}

.arrow {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #000;
}

.arrow:first-of-type {
  left: 10px;
}

.arrow:last-of-type {
  right: 10px;
}
