/* Slider container styling */
.sliderContainer {
  width: 80%; /* أو 100% حسب الحاجة */
  margin: auto;
  overflow: hidden;
  border-radius: 15px;
}

/* Slide styling */
.slide {
  position: relative;
}

/* Image styling */
.image {
  width: 100%;
  height: 500px; /* اضبط حسب الحاجة */
  object-fit: cover;
  border-radius: 15px;
}

/* Text styling - Centered */
.text {
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
  color: #000000;
  font-size: 24px;
  /* font-weight: bold; */
  text-align: center;
  /* text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7); */
  padding: 10px;
  width: 30%; /* لجعل النص منسقًا جيدًا */
  pointer-events: none; /* منع تداخل النص مع التمرير */
}
/* @media(max-width: 678px) {
  .text {
    font-size: 20px;
    color: red;
  }
} */

