.modelSlider {
  width: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* تأكد من استخدام position relative للحاوية */
  padding: 0 20px;
}
.modelSlider  img {
  width: 200px;
}
.modelSlider  h2 {
  text-align: center;
  font-size: 20px;
}
.modelCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.modelImage {
  height: auto;
  border-radius: 10px;
}

.modelName {
  text-align: center;
  margin: 0 auto;
  font-size: 16px;
  font-weight: bold;
  color: red;
  padding-bottom: 35px;
  /* new */
  /* padding-top: 15px; */

}

@media(max-Width: 758px) {
  .modelSlider {
    width: 90%;
  }
}

.modelSlider {
  /* إذا كانت اللغة العربية مستخدمة */
  direction: ltr; /* للحفاظ على حركة السلايدر */
}

.modelCard {
  text-align: center; /* لضمان أن النصوص تكون في منتصف البطاقة */
}